<template>
  <div>
    <step-header/>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <!--jimir3x-->
      <div class="step-content step-content--step-3-product-add zoom">
        <!--jimir3x-->
        <loading :loading="loading" />
        <div v-if="!loading" class="container " style="">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="row">
                <div class="col-md-12 mb-22">
                  <validation-provider
                    name="Industry" 
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="input-group-3"
                     
                      label-for="input-3"
                    >
                          <template v-slot:label>
                  {{$t('crt-quote.step2.label')}} <span class="required">*</span>
                    </template>
                    
                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        id="industry"
                        v-model="form.industry.id"
                        :options="industrys"
                        value-field="id"
                        text-field="name"
                        placeholder="Enter email"
                        required
                      ></b-form-select>
                    </b-form-group>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>

                <div class="col-md-12 mb-22">
                  <validation-provider
                    name="Product Name"
                    :rules="{ itemName: true }"
                    v-slot="validationContext"
                  >
                    <div class="form-group mb-22">
                      <label for>
                        {{ $t('crt-quote.step3.label-1')
                        }}<span class="required mr-3">*</span>
                        <small style="color: c5c5c5">
                          {{ $t('crt-quote.step3.label-desc') }}</small
                        >
                      </label>
                      <input spellcheck="true"
                        v-model="form.name"
                        :class="{
                          'is-invalid':
                            (
                                !getValidationState(validationContext) 
                                && 
                                validationContext.errors[0]
                            )
                        }"
                        type="text"
                        class="form-control"
                        :placeholder="$t('crt-quote.step3.ph1')"
                      />
                      <small v-if="validationContext.errors[0]" class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </div>
                  </validation-provider>
                </div>

                <div class="col-md-12 mb-22">
                  <validation-provider
                    name="Product ID"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <div class="form-group">
                      <label for>
                        {{ $t('crt-quote.step3.label-2') }}
                        <span class="required">*</span>
                      </label>
                      <input spellcheck="true"
                        v-model="form.id"
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        type="text"
                        class="form-control"
                        :placeholder="$t('crt-quote.step3.ph2')"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </div>
                  </validation-provider>
                </div>

                <div class="col-md-12 mb-22">
                  <div class="row register-step-add-product">
                    <div class="col-md-3">
                      <validation-provider
                        name="units"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="input-group-3"
                         
                          label-for="input-3"
                        >
 
                            <template v-slot:label>
                  {{$t('crt-quote.step5.label-1')}} <span class="required">*</span>
                    </template>
                        
                          <b-form-select
                            :class="{
                              'is-invalid':
                                !getValidationState(validationContext) &&
                                validationContext.errors[0],
                            }"
                            @change="changeUnitName()"
                            id="unit"
                            v-model="form.selectedUnit.id"
                            :options="units"
                            value-field="id"
                            text-field="name"
                            required
                          ></b-form-select>
                        </b-form-group>
                        <small class="text-danger">{{
                          validationContext.errors[0]
                        }}</small>
                      </validation-provider>
                    </div>

                    <div class="col-md-3">
                      <validation-provider
                        name="currency"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="input-group-3"
                          
                          label-for="input-3"
                        >

                           <template v-slot:label>
                  {{$t('crt-quote.step5.label-2')}} <span class="required">*</span>
                    </template>


                          <b-form-select
                            :class="{
                              'is-invalid':
                                !getValidationState(validationContext) &&
                                validationContext.errors[0],
                            }"
                            id="currency"
                            @change="selectedCurrencyCode()"
                            v-model="form.currency.id"
                            :options="currencies"
                            value-field="id"
                            text-field="name"
                            required
                          >
                          </b-form-select>
                        </b-form-group>
                        <small class="text-danger">{{
                          validationContext.errors[0]
                        }}</small>
                      </validation-provider>
                    </div>

                    <div class="col-md-3">
                      <validation-provider
                        name="Minimum Price"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <label for>
                          {{ $t('crt-quote.step5.label-3') }}
                          <span class="required">*</span>
                        </label>
                        <currency-input
                          :currency="inputCurrency"
                          v-model="form.price.min"
                          :class="{
                            'is-invalid':
                              !getValidationState(validationContext) &&
                              validationContext.errors[0],
                          }"
                          class="form-control"
                          style="height: 42px"
                        />
                        <small class="text-danger">{{
                          validationContext.errors[0]
                        }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-md-3">
                      <validation-provider
                        name="Maximum Price"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <label for>
                          {{ $t('crt-quote.step5.label-4') }}
                          <span class="required">*</span>
                        </label>
                        <currency-input
                          :currency="inputCurrency"
                          v-model="form.price.max"
                          :class="{
                            'is-invalid':
                              !getValidationState(validationContext) &&
                              validationContext.errors[0],
                          }"
                          class="form-control"
                          style="height: 42px"
                        />
                        <small class="text-danger">{{
                          validationContext.errors[0]
                        }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mb-34">
                <div class="row">
                  <div class="col">
                    <validation-provider
                      name="moq"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <label for>
                        {{ $t('crt-quote.step5.label-5') }} <span class="required">*</span>
                        <span v-if="form.selectedUnit.name" class="step3-units"
                          >( {{ form.selectedUnit.name }} )</span
                        >
                      </label>
                      <input spellcheck="true"
                        v-model="form.stock"
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </validation-provider>
                  </div>
                  <div class="col">
                    <validation-provider
                      name="Stock"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <label for>
                        {{ $t('crt-quote.step5.label-6') }} <span class="required">*</span>
                        <span v-if="form.selectedUnit.name" class="step3-units"
                          >( {{ form.selectedUnit.name }} )</span
                        ></label
                      >
                      <input spellcheck="true"
                        v-model="form.moq"
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <!--<div class="form-group">
                <div class="w-50 mr-4">
                  <validation-provider
                    name="Stock"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>Stock</label>
                    <input spellcheck="true"
                      v-model="form.stock"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      type="number"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div> -->

              <!-- kamil moq -->

              <!-- <div class="w-50 mr-4">
                  <validation-provider
                    name="Stock"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>M.O.Q</label>
                    <input spellcheck="true"
                      v-model="form.moq"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      type="number"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div> -->

              <!-- kamil moq -->

              <!-- <div class="form-group mb-34 d-flex justify-content-between">
                <div class="w-50 ml-4">
                  <validation-provider
                    name="Currency"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Currency
                      <span class="required">*</span>
                    </label>
                    <select
                      @change="selectCurrency"
                      v-model="form.currency"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control w-100"
                    >
                      <option hidden :value="null" selected>Select</option>
                      <option
                        v-for="(item, index) in currencies"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>

                <div class="w-50 mr-4">
                  <validation-provider
                    name="Minimum Price"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Min. Price
                      <span class="required">*</span>
                    </label>
                    <currency-input
                      :currency="inputCurrency"
                      v-model="form.price.min"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="w-50 ml-4">
                  <validation-provider
                    name="Maximum Price"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for>
                      Max.Price
                      <span class="required">*</span>
                    </label>
                    <currency-input
                      :currency="inputCurrency"
                      v-model="form.price.max"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      class="form-control"
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>
              <div
                class="form-group mb-34 d-flex justify-content-between"
              ></div> -->

              <validation-provider
                name="Product Description"
                :rules="{ itemDescription: true }"
                v-slot="validationContext"
              >
                <div class="form-group mb-34">
                  <label for> {{ $t('crt-quote.step3.label-3') }} <span class="required">*</span></label>
                  <textarea spellcheck="true"
                    v-model="form.desc"
                    :class="{
                      'is-invalid':
                        (
                            !getValidationState(validationContext) 
                            &&
                            validationContext.errors[0]
                        )
                    }"
                    type="text"
                    rows="7"
                    class="form-control"
                    style="min-height:150px"
                    :placeholder="$t('crt-quote.step3.ph3')"
                  ></textarea>
                   <div class="counter">
                  <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                  <div class="number-back-counter">
                    {{ counter }} / 1200
                  </div>
                  </div>
                </div>
              </validation-provider>
              <label for>
                {{ $t('crt-quote.step4.images') }}
                <span class="required">*</span>
              </label>
              <div class="dropzone-content mb-34">
                <vue-dropzone
                  @vdropzone-files-added="fileUpload"
                  @vdropzone-thumbnail="getThumbnail"
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t('crt-quote.step4.drop-img') }}
                    </h3>
                    <small class="text-muted d-block">
                      {{ $t('crt-quote.step4.or') }}</small
                    >
                    <button @click.prevent class="btn btn-primary">
                      {{ $t('buttons.addimage') }}
                    </button>
                    <p>
                      JPG {{ $t('crt-quote.step4.or') }}PNG 150 kb
                      {{ $t('crt-quote.step4.max') }}
                    </p>
                  </div>
                </vue-dropzone>
                <div
                  class="img-preview-content-auth mt-3 d-flex justify-content-between"
                  style="background: white"
                >
                  <div v-for="(b, i) in 5" :key="i" class="item mb-34">
                    <div
                      @click.prevent="deleteThumbnail(i)"
                      v-if="productImages[i]"
                      class="clearBtn"
                    >
                      X
                    </div>
                    <img
                      :src="!productImages[i] ? noImage : productImages[i].src"
                      alt
                    />
                  </div>
                </div>
              </div>
              <!-- <div
                class="form-group d-flex align-items-center step-save-button-container"
                :class="
                  getUserList.list.length > 0
                    ? 'justify-content-between'
                    : 'justify-content-end'
                "
              >
                <router-link
                  v-if="getUserList.list.length > 0"
                  :to="'/register-steps/step-3/products'"
                  class="d-block mr-2"
                >
                  {{ $t('buttons.cancel') }}
                </router-link>

<hr>
                <button
                  @click="handleSubmit(formSubmit)"
                  class="btn btn-primary"
                  
                >
                  {{ $t('buttons.save') }}
                </button>

               

                

              </div> -->
              
            </div>
          </div>
        </div>
      </div>
      <step-footer v-if="!loading" :disabled="invalid" :skipable="true" @skip="skip()" @submit="handleSubmit(formSubmit)" />
    </validation-observer>
  </div>
</template>

<script>
import StepFooter from '../../components/register-step/step-footer';
import baseImage from '../../assets/img/product-add-no-image.png';
import getUnit from '@/graphql/public/getUnit.gql';
import getCurrencies from '@/graphql/public/getCurrencies.gql';
import getIndustry from '@/graphql/public/getIndustry.gql';
import addProduct from '@/graphql/me/addProduct.gql';
import getProducts from '@/graphql/me/getProducts.gql';
import Loading from '@/components/global/loading';
import { mapGetters, mapMutations } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import StepHeader from '@/components/register-step/step-header.vue';
import _ from 'lodash';
export default {
  components: {
    StepFooter,
    Loading,
    Treeselect,
    StepHeader
  },
  data() {
    return {
      maxDesc: 300,
      remainingDesc: 300,
      noImage: baseImage,
      form: {
        images: [],
        desc: '',
        currency: {
          id: null,
          name: this.$t('crt-quote.step5.ph-2'),
        },
        price: {},
        industry: {
          id: null,
          name: this.$t('crt-quote.step2.ph'),
        },
        units: [],
        selectedUnit: {
          id: null,
          name: '',
        },
        moq: null,
      },
      inputCurrency: null,
      loading: false,
      currencies: [],
      productImages: [],
      industrys: [],
      units: [],
      dropzoneOptions: {
        createImageThumbnails: true,
        addRemoveLinks: true,
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        thumbnailWidth: null,
        thumbnailHeight: null,
        acceptedFiles: 'image/*',
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
      }
    };
  },
  computed: {
    ...mapGetters({
      getUserList: 'inquiries/getUserList',
    }),
    counter() {
        return _.size(this.form.desc);
    }
  },
  
  methods: {
    ...mapMutations({
      setUserList: 'inquiries/setUserList',
      setCompleteStep: 'general/setCompleteStep'
    }),

    changeUnitName() {
      this.$nextTick(x => {
        this.form.selectedUnit.name = this.units.find(
          x => x.id == parseInt(this.form.selectedUnit.id)
        ).name;
      });
    },
    skip() {
        this.setCompleteStep(4);
        return this.$router.push('/register-steps/step-4');
    },
    async formSubmit() {
      try {
        if (this.form.price.max <= this.form.price.min) {
          //
          return this.$bvToast.toast(this.$t("Toaster.max-price-ex"),
            {
               title: this.$t("Toaster.error-title"),
              variant: 'danger',
              aautoHideDelay: 1350,
            }
          );
        }
        this.loading = true;
        let payload = {
          product_id: this.form.id,
          industry_id: parseInt(this.form.industry.id),
          name: this.form.name,
          unit_id: parseInt(this.form.selectedUnit.id),
          currency_id: parseInt(this.form.currency.id),
          min_price: this.form.price.min,
          max_price: this.form.price.max,
          stock: parseInt(this.form.stock),
          moq: parseInt(this.form.moq),
          description: this.form.desc,
          images: this.form.images,
        };
        let data = await this.$apollo.mutate(addProduct, {
          product: payload,
        });
        await this.getProducts();
        this.$router.push('/register-steps/step-3/products');
      } catch (e) {
        let splited = e.message.split('.');
        let err = splited.pop();
        let errType = splited.pop();
        let msg = this.$t(`${errType}.${err}`);
        let par = {
          title: this.$t('general.error'),
          message: msg,
          type: 'danger',
          hide: true,
        };
        this.makeToast(par);
        this.scrollToTop();
      } finally {
        this.loading = false;
      }
    },
    selectCurrency() {
      let handler = this.currencies.find(
        item => item.id === this.form.currency
      );
      this.inputCurrency = handler.code;
    },
    async getProducts() {
      let params = {
        limit: 10,
        filters: {},
      };
      let data = await this.$apollo.query(getProducts, params);
      let response = data('**.list');

      this.setUserList(response);
      return response;
    },
    async getUnits() {
      try {
        let data = await this.$apollo.query(getUnit);
        let response = data('**.units');
        this.units = response;

        this.units.unshift({ id: null, name: this.$t('crt-quote.step5.ph1') });
        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
      } finally {
      }
    },
    async getIndustry() {
      try {
        let par = {
          type: 'PRODUCT_AND_INQUIRY',
        };
        let data = await this.$apollo.query(getIndustry, par);
        let response = data('**.industries');
        this.industrys = response;

        this.industrys.unshift({
          id: null,
          name: this.$t('crt-quote.step2.ph'),
        });

        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
      } finally {
      }
    },
    deleteThumbnail(index) {
      this.productImages.splice(index, 1);
      this.productImages = [...this.productImages];
      this.form.images.splice(index, 1);
      this.form.images = [...this.form.images];
    },
    getThumbnail(file, dataUrl, $event) {
      if (this.productImages.length === 5) {
        return;
      }
      this.form.images.push(file);
      this.productImages.push({
        src: dataUrl,
      });
    },
    errorsUpload(file, dataUrl) {},
    fileUpload(file) {
      if (this.productImages.length === 5) {
        this.$bvToast.toast(this.$t("Toaster.img-add-up"), {
          title: this.$t("Toaster.error-title"),
          autoHideDelay: 1350,
        });
        return false;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }

      return null;
    },
    closeSelect(value, id) {
      if (id == 'industry') {
        if (!this.form.industry) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == 'years') {
        if (!this.form.years) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == 'positions') {
        if (!this.form.position) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },
    async getCurrencies() {
      let data = await this.$apollo.query(getCurrencies);
      let response = data('**.currencies');
      this.currencies = response;

      this.currencies.unshift({
        id: null,
        name: this.$t('crt-quote.step5.ph-2'),
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getUnits();
    await this.getIndustry();
    await this.getCurrencies();
    await this.getProducts();
    this.loading = false;
  },
};
</script>

<style scoped>
input {
  height: 42px !important;
}
</style>
